import React, {useState} from 'react';
import Layout from "../components/Layout";
import image from '../img/what-we-have-learnt.png';
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import {graphql} from "gatsby";
import showdown from 'showdown';
import BreadCrumb from "../components/BreadCrumb";

const converter = new showdown.Converter();
export const WhatWeLearntTemplate = ({introtext, articles}) => {
  const [index, setIndex] = useState(0);

  function getButtonClass(i) {
    return i === index ? 'article-button is-transparent active' : 'article-button is-transparent';
  }
  const links = [
    {label: 'Home', value: '/'}
  ];

  return <div className="container what-we-learnt-page">
    <BreadCrumb links={links} currentPage={'What we have learnt'}/>
    <div className={'intro-image'}>
      <PreviewCompatibleImage imageInfo={{image: image}} alt={'intro'}/>
    </div>
    <div className={'intro-text content'} dangerouslySetInnerHTML={{__html: converter.makeHtml(introtext)}}/>
    <div className={'articles'}>
      <div className={'articles-menu'}>
        {articles.map((article, i) => <button onClick={() => setIndex(i)}
                                              className={getButtonClass(i)}>{article.title}</button>)}
      </div>
      <div className={'article-text content'} dangerouslySetInnerHTML={{__html: converter.makeHtml(articles[index].content)}}/>
    </div>
  </div>
}

const WhatWeLearnt = ({data}) => {
  const {frontmatter} = data.markdown;

  return <Layout>
    <WhatWeLearntTemplate
      introtext={frontmatter.intro_text}
      articles={frontmatter.articles}
    />
  </Layout>;
}

export default WhatWeLearnt;

export const pageQuery = graphql`
  query WhatWeLearnt {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-have-learnt" } }
    ) {
       frontmatter {
        intro_text
        articles {
          content
          title
        }
      }
    }
  }
`
